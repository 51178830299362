<template>
    <div class="main-wrapper">
<!--        <div class="main-tab">考试管理</div>-->
        <div class="main-top">
            <div class="top-item">
                <div class="item">
                    <span class="item-text">考试状态</span>
                    <el-select v-model="examStatus" placeholder="请选择" style="width: 200px" @change="changeStatus">
                        <el-option v-for="item in examStatusList" :key="item.value" :label="item.text" :value="item.status"></el-option>
                    </el-select>
                </div>
            </div>
            <div class="top-item">
                <div class="item">
                    <el-button type="primary" plain @click="changeExamTime">修改考试时间</el-button>
                </div>
                <div class="item">
                    <el-button type="primary" @click="addExam">创建考试</el-button>
                </div>
            </div>
        </div>
        <el-table :data="examList" class="customTable" style="width: 100%; flex: 1" height="1%"
                  :header-cell-style="{fontWeight: 'normal', height: '60px', color: '#666666', background: '#F6F6F6',fontSize: '16px'}"
                  :cell-style="{fontSize: '16px',color: '#333333',height: '70px'}">
            <el-table-column prop="name" label="考试名称"></el-table-column>
            <el-table-column prop="status" label="考试状态" align="center" width="100px"></el-table-column>
            <el-table-column prop="class_name" label="班级" align="center" width="100px"></el-table-column>
            <el-table-column prop="exam_num" label="考试人数" align="center" width="100px"></el-table-column>
            <el-table-column prop="teacher_num" label="评委数量" align="center" width="100px"></el-table-column>
            <el-table-column prop="begin_time" label="开始时间" align="center"></el-table-column>
            <el-table-column prop="end_time" label="结束时间" align="center"></el-table-column>
            <el-table-column label="操作" align="center" width="200px">
                <template slot-scope="scope">
                    <el-link type="primary" v-if="scope.row.status === '进行中'" :underline="false" @click="endBtn(scope.row.id)">结束考试</el-link>
                    <el-link type="success" :underline="false" @click="editBtn(scope.row.id, scope.row)">编辑</el-link>
                    <el-link type="danger" :underline="false" @click="delBtn(scope.row.id)">删除</el-link>
                </template>
            </el-table-column>
        </el-table>
        <el-pagination class="pages-center" style="margin: 20px 0"
                       :current-page="listPages.currentPageNum"
                       :page-size="listPages.eachPageNum"
                       layout="prev, pager, next, jumper"
                       :total="listPages.total"
                       @current-change="pageCurrentChange">
        </el-pagination>

        <el-dialog title="修改考试时间" :visible.sync="dialogVisible" width="500px" custom-class="custom-dialog"
                   :close-on-click-modal="false" @opened="openedDialog" @close="closeDialog">
            <el-form :model="addForm" :rules="rules" ref="addForm" label-width="100px" class="demo-ruleForm" :hide-required-asterisk="true">
                <el-form-item label="考试名称" prop="id">
                    <el-select filterable v-model="addForm.id" placeholder="请选择" style="width: 100%" @change="changeExam">
                        <el-option v-for="(item, index) in examList" :key="`examListForm_${index}`" :label="item.name" :value="item.id"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="学生名称" prop="student">
                    <el-select filterable v-model="addForm.student" placeholder="请选择" clearable multiple @change="changeStudent"
                               collapse-tags style="width: 100%" :disabled="studentList.length === 0">
                        <el-option v-for="(item, index) in studentList" :disabled="addForm.student.includes(0)"
                                   :key="`studentList_${index}`" :label="item.name" :value="item.id"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="结束时间" prop="end_time">
                    <el-date-picker type="datetime" value-format="yyyy-MM-dd HH:mm:ss" :picker-options="startTimeOptions" @focus="clickStartTime"
                                    placeholder="选择结束日期时间" v-model="addForm.end_time" style="width: 100%;"></el-date-picker>
                </el-form-item>
            </el-form>
            <div slot="footer" style="text-align: center">
                <el-button @click="dialogVisible = false">取消</el-button>
                <el-button type="primary" @click="submitForm('addForm')" style="margin-left: 30px">保存</el-button>
            </div>
        </el-dialog>
    </div>
</template>

<script>
    import { examList, examEnd, examEditTime, examDel, examStu } from '@/utils/apis.js'
    export default {
        data() {
            return {
                examStatus: 0,
                examStatusList: [
                    {
                        status: 0,
                        text: '全部',
                    },
                    {
                        status: 1,
                        text: '未开始',
                    },
                    {
                        status: 2,
                        text: '进行中',
                    },
                    {
                        status: 3,
                        text: '已结束',
                    },
                ],
                examList: [],
                listPages: {
                    currentPageNum: 1,
                    eachPageNum: 10,
                    total: 0,
                },
                // S弹窗
                dialogVisible: false,
                addForm: {
                    id: null,
                    student: [],
                    end_time: null,
                },
                rules: {
                    id: [
                        { required: true, message: '请选择考试名称', trigger: 'change' },
                    ],
                    student: [
                        { required: true, message: '请选择学生', trigger: 'change' },
                    ],
                    end_time: [
                        { required: true, message: '请选择结束时间', trigger: 'change' },
                    ],
                },
                studentList: [],
                startTimeOptions: {},
                // E弹窗
            }
        },
        mounted() {
            this.getList()
        },
        methods: {
            clickStartTime() {
                this.startTimeOptions.disabledDate = time => {
                    if (time.getTime() < Date.now() - 8.64e7) {
                        return true
                    }
                }
            },
            // 获取列表
            getList() {
                let params = {
                    paging: 1,
                    page: this.listPages.currentPageNum,
                    pageSize: this.listPages.eachPageNum,
                    status: this.examStatus,
                }
                examList(params).then((res) => {
                    this.examList = res.data.data
                    this.listPages.total = res.data.total
                })
            },
            // 筛选状态
            changeStatus(val) {
                this.listPages.currentPageNum = 1
                this.getList()
            },
            // 切换页面
            pageCurrentChange(val) {
                this.listPages.currentPageNum = val
                this.getList()
            },
            // 创建考试
            addExam() {
                this.$router.push('/examCenter/create')
            },
            // 结束考试
            endBtn(id) {
                this.$confirm('确定结束考试吗？', '提示消息', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    customClass: 'green-message-box',
                    type: 'warning',
                }).then(() => {
                    let params = {
                        id: id
                    }
                    examEnd(params).then((res) => {
                        this.$message.success(res.msg)
                        this.getList()
                    }).catch((err) => {})
                }).catch(() => {
                    this.$message.info('已取消结束');
                })
            },
            // 编辑考试
            editBtn(id, row) {
                if (row.status === '进行中') {
                    return this.$message.warning('该考试正在进行中，不能编辑')
                } else if (row.status === '已结束') {
                    this.$router.push({
                        path: '/examCenter/create',
                        query: {
                            id: id,
                            status: true
                        }
                    })
                } else {
                    this.$router.push({
                        path: '/examCenter/create',
                        query: {
                            id: id
                        }
                    })
                }
            },
            // 删除考试
            delBtn(id) {
                this.$confirm('确定删除考试吗？', '提示消息', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    customClass: 'green-message-box',
                    type: 'warning',
                }).then(() => {
                    let params = {
                        id: id
                    }
                    examDel(params).then((res) => {
                        this.$message.success(res.msg)
                        this.getList()
                    }).catch((err) => {})
                }).catch(() => {
                    this.$message.info('已取消删除');
                })
            },
            // 修改考试时间
            changeExamTime() {
                this.dialogVisible = true
            },
            // 打开弹窗
            openedDialog() {

            },
            // 关闭弹窗
            closeDialog() {
                this.resetForm()
                this.studentList = []
            },
            // 筛选考试
            changeExam(val) {
                // 获取学生列表
                this.addForm.student = []
                this.studentList = []
                this.getStudentList()
            },
            // 获取学生列表
            getStudentList() {
                let params = {
                    id: this.addForm.id
                }
                examStu(params).then((res) => {
                    let tmp = {
                        id: 0,
                        name: '全部'
                    }
                    res.data.unshift(tmp)
                    this.studentList = res.data
                })
            },
            // 弹窗内确定
            submitForm(formName) {
                this.$refs[formName].validate((valid) => {
                    if (valid) {
                        let formData = new FormData()
                        formData.append('id', this.addForm.id)
                        formData.append('end_time', this.addForm.end_time)
                        if (this.addForm.student.includes(0)) {
                            formData.append('student', 0)
                        } else {
                            formData.append('student', this.addForm.student.join('#'))
                        }
                        examEditTime(formData).then((res) => {
                            this.$message.success(res.msg)
                            this.getList()
                            this.dialogVisible = false
                        })
                    } else {
                        return false;
                    }
                });
            },
            // 重置
            resetForm() {
                this.$refs.addForm.resetFields();
                this.addForm = {
                    id: null,
                    student: [],
                    end_time: null,
                }
            },
            changeStudent(val) {
                if (val.includes(0)) {
                    val = [0]
                    this.addForm.student = val
                }
            }
        }
    }
</script>

<style scoped lang="scss">
    .main-wrapper {
        height: 100%;
        display: flex;
        flex-direction: column;
        padding: 0 20px;
    }
    .main-tab {
        font-size: 16px;
        line-height: 1;
        padding-bottom: 14px;
        border-bottom: 1px solid #F1F5FF;
    }
    .main-top {
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-wrap: wrap;
        /*margin-top: 20px;*/
        .top-item {
            display: flex;
            align-items: center;
            margin-bottom: 20px;
            .item-text {
                margin-right: 12px;
            }
            .item + .item {
                margin-left: 20px;
            }
        }
    }
    .demo-ruleForm {
        .el-form-item {
            margin-bottom: 16px;
        }
        ::v-deep .el-form-item__label {
            font-size: 16px;
            color: #666;
        }
        ::v-deep .el-form-item__error {
            padding-top: 2px;
        }
    }
</style>